<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
  
      <!-- 검색창 -->
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <select v-model="searchCriteria" class="search-select">
              <option value="item">기체관리명</option>
              <option value="msg_name">템플릿명</option>
              <option value="send_period">발송일</option>
              <option value="send_time">발송시간</option>
            </select>
            <input
              v-model="searchQuery"
              type="text"
              :placeholder="`검색할 내용을 입력하세요`"
              class="search-input"
              @input="filterResults"
            />
            <button class="btn-search" @click="filterResults">검색</button>
            <button class="btn-register" @click="goToRegister">등록</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
  
      <!-- 테이블 -->
      <table>
        <thead>
          <tr>
            <th>NO.</th>
            <th>기체관리명</th>
            <th>템플릿명</th>
            <th>템플릿내용</th>
            <th>발송일</th>
            <th>발송시간</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">
            <td colspan="5">데이터가 없습니다.</td>
          </tr>
          <tr
            v-for="(item, index) in paginatedData"
            :key="index"
            :class="{ 'alt-row': index % 2 === 1 }"
            @click="goToUpdateDelete(item)"
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ item.itemName }}</td>
            <td>{{ item.msg_name }}</td>
            <td>{{ item.msg_contents }}</td>
            <td>{{ `${item.send_period} 일전` }}</td>
            <td>{{ item.send_time }}</td>
          </tr>
        </tbody>
      </table>
  
      <!-- 페이지네이션 -->
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage >= pageCount || pageCount === 0">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
  import axios from "@/axios";
  
  export default {
    components: {
      mdbContainer,
      mdbRow,
      mdbCol,      
    },
    data() {
      return {
        pageTitle: "발송 주기 조회",
        results: [],
        filteredResults: [],
        searchCriteria: "item",
        searchQuery: "",
        currentPage: 1,
        perPage: 10,
      };
    },
    

    computed: {
      paginatedData() {
        const start = (this.currentPage - 1) * this.perPage;
        return this.filteredResults.slice(start, start + this.perPage);     
       
      },

      pageCount() {
        return Math.ceil(this.filteredResults.length / this.perPage);
      },
    },
    
    methods: {
      async fetchResults() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          const response = await axios.get("/sms-period/read_period", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          this.results = response.data.map((item) => ({
            ...item,
            itemName: this.getItemName(item.item), // 기체관리명 변환
          }));
          //this.filteredResults = this.results;
          //console.log("초기 로드 데이터:", this.results); // 디버그용
          this.filteredResults = [...this.results]; // 초기화
          

        } catch (error) {
          console.error("데이터 조회 실패:", error);
        }
      },
  
      getItemName(item) {
        switch (item) {
          case "0":
            return "보험";
          case "1":
            return "기체 안정성";
          case "2":
            return "비행승인";
          default:
            return "알 수 없음";
        }
      },
  
      // filterResults() {
      //   const query = this.searchQuery.trim().toLowerCase();
      //   //console.log("검색 기준:", this.searchCriteria); // 디버그용
      //   //console.log("검색어:", query); // 디버그용

      //   if (!query) {
      //     this.filteredResults = [...this.results];
          
      //     return;
      //   }

      //   this.filteredResults = this.results.filter((item) => {
      //     let value = "";

      //     if (this.searchCriteria === "item") {
      //       value = this.getItemName(item.item).toLowerCase(); // `itemName`으로 변환
      //     } else {
      //       value = item[this.searchCriteria]?.toString().toLowerCase() || "";
      //     }

      //     //console.log("필터링 값:", value); // 디버그용
      //     return value.includes(query);
      //   });

      //   //console.log("필터링 결과:", this.filteredResults); // 디버그용
      //   this.currentPage = 1;
      // },
      filterResults() {
          const query = this.searchQuery.trim().toLowerCase();

          this.currentPage = 1; 
          this.filteredResults = this.results.filter((item) => {
              const value = 
                  this.searchCriteria === "item"
                      ? this.getItemName(item.item).toLowerCase()
                      : item[this.searchCriteria]?.toString().toLowerCase() || "";
              return value.includes(query);
          });

         
      },

  
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.pageCount) this.currentPage++;
      },
      goToRegister() {
        //console.log("현재 results 데이터:", this.results);
        this.$router.push({
          name: "sms-period-create",
          // query: {
          //   existingPeriods: JSON.stringify(
          //     this.results.map((item) => ({
          //       send_period: item.send_period,
          //       item: item.item, // 추가
          //     }))
          //   ),
          // },
        });
      },
      goToUpdateDelete(record) {
        if (!record) {
          //console.error("record가 전달되지 않았습니다.");
          return;
        }
        this.$router.push({
            name: "sms-period-updateDelete",
            query: {
                selectedRecord: JSON.stringify(record), // JSON.stringify로 직렬화
            },
        });
      },
    },
    mounted() {
      this.fetchResults();
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-container {
    padding: 25px;
  }
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    color: #3e3e3e;
    margin-right: 20px;
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    color: #3e3e3e;
    margin-left: auto;
  }
  
  table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  </style>
  